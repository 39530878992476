import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const PdfWithHeaderExport = ({
    cols,
    formatDataForExport,
    getFormatedDataSet,
    name,
    buttonclass = 'btn',
    loading = false,
    grouppedData = false,
    size = 'a1', // use A1, A2, A3 or A4
    orientation = 'portrait', // portrait or landscape
    fontStyle = 'bold',
    buttonName = 'PDF Export',
}) => {
    const exportPDF = async (e) => {
        e.preventDefault();
        const unit = 'pt';

        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(5);
        const headers = [cols.map((c) => c.label)];

        const dataSet = getFormatedDataSet();
        for (let i = 0; i < dataSet.length; i++) {
            const dataSetToFormat = dataSet[i].tableData;
            let formattedDataSet = await formatDataForExport(dataSetToFormat);
            if (!grouppedData) formattedDataSet = formattedDataSet.map((elt) => [...Object.values(elt)]);

            let content = {
                head: headers,
                body: formattedDataSet,
                rowPageBreak: 'avoid',
                styles: {
                    font: 'courier',
                    fontStyle,
                    halign: 'center',
                    valign: 'middle',
                },
                startY: 180,
                margin: 10,
            };

            doc.setFontSize(16);

            const headerFields = dataSet[i].headerData;
            Object.keys(headerFields).map((title, i) => {
                doc.setFont(undefined, 'bold');
                doc.text(title || '', 75, 30 + 24 * i);
                doc.setFont(undefined, 'normal');
                doc.text(headerFields[title] || '', 300, 30 + 24 * i);
            });

            doc.autoTable(content);
            if (i < dataSet.length - 1) {
                doc.addPage();
            }
        }
        doc.save(`${name}.pdf`);
    };

    return (
        <button className={buttonclass} disabled={loading} onClick={exportPDF}>
            {buttonName}
        </button>
    );
};

export default PdfWithHeaderExport;
