import React, { useEffect, useState } from 'react';
import { getProfileInfo, useProfilePageState, useAccountState, getTripSettings } from '../../../../Context';
import DetailsPageLayout from '../../../../Components/DetailsPageLayout/DetailsPageLayout';
import { Col, Form, FormGroup, Row, Card, Button } from 'react-bootstrap';
import Loader from '../../../../Components/Loader';
import { useForm } from 'react-hook-form';
import CustomAccordion from '../../../../Components/CustomAccordion';
import './index.scss';
import { getPayrollPeriodsService } from '../../../../services/payrollPeriodsService';
import moment from 'moment';
import SimpleTable from '../../../../Components/SimpleTable';
import PdfWithHeaderExport from '../../../../Components/PdfWithHeaderExport';

import { getPayrollEntriesService } from '../../../../services/payrollEntriesService';
import { isEqual, sumBy } from 'lodash';
import { calculateTotal } from '../../../../utils/helper';

const ProfilePage = () => {
    const {
        profilePage: { profileInfos },
        profilePageDispatch,
    } = useProfilePageState();
    const [loading, setLoading] = useState(false);
    const { reset, register } = useForm();
    const [profileTitle, setProfileTitle] = useState();
    const [collapseItem, handleCollapse] = useState({ 1: false });
    const [payrollPeriodID, setPayrollPeriodID] = useState();
    const [payrollPeriods, setPayrollPeriods] = useState([]);
    const [payrollEntries, setPayrollEntries] = useState([]);
    const [selectedPayrollPeriod, setSelectedPayrollPeriod] = useState();
    const [loadingPE, setLoadingPE] = useState(false);

    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getTripSettings(AccountsDispatch);
            let driverDetail = await getProfileInfo(profilePageDispatch);
            reset({ ...driverDetail });
            driverDetail && setProfileTitle(`${driverDetail.firstName}  ${driverDetail.lastName}`);
            let { data: pplist } = await getPayrollPeriodsService();
            setPayrollPeriods(pplist);
            const tempPPID = pplist.find(({ startDate, endDate }) =>
                moment().isBetween(moment(startDate), moment(endDate))
            );

            if (tempPPID?.payrollPeriodID) setPayrollPeriodID(tempPPID.payrollPeriodID);
            setLoading(false);
        })();
    }, [AccountsDispatch, profilePageDispatch, reset]);

    useEffect(() => {
        (async () => {
            if (payrollPeriodID) {
                setLoadingPE(true);
                const { data: fetchedPayrollEntries } = await getPayrollEntriesService(payrollPeriodID);
                setPayrollEntries(fetchedPayrollEntries);
                setLoadingPE(false);
            }
            if (payrollPeriodID) {
                setSelectedPayrollPeriod(payrollPeriods.find((pp) => pp?.payrollPeriodID == payrollPeriodID));
            }
        })();
    }, [payrollPeriodID, payrollPeriods]);

    const handleItemCollapse = (e) => {
        const { id } = e.target;
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] });
    };

    const getTotalHoursRecords = () => {
        const records = [];
        payrollEntries
            .filter((pe) => [1, 2, 3, 4, 7].includes(pe.payRateDataSourceID))
            .forEach((pe) => {
                const recordAlreadyExists = records.some((r) =>
                    isEqual(
                        {
                            description: r.description,
                            startTime: r.startTime,
                            endTime: r.endTime,
                            quantity: r.quantity,
                        },
                        {
                            description: pe.description,
                            startTime: pe.startTime,
                            endTime: pe.endTime,
                            quantity: pe.quantity,
                        }
                    )
                );

                if (!recordAlreadyExists) {
                    records.push(pe);
                }
            });
        return records;
    };

    const payrollEntriesCol = [
        { key: 'startTime', label: 'Start Date' },
        { key: 'endTime', label: 'End Date' },
        { key: 'description', label: 'Description' },
        { key: 'destination', label: 'Destination' },
        { key: 'tripReference', label: 'Trip Reference' },
        { key: 'payRateType', label: 'Pay Rate Type' },
        { key: 'quantity', label: 'Quantity' },
        { key: 'units', label: 'Units' },
        { key: 'payrate', label: 'Pay Rate' },
        { key: 'famount', label: 'Amount' },
    ];

    const headerFields = {
        'Driver Name:': `${profileInfos?.firstName} ${profileInfos?.lastName}`,
        'Driver ID:': `${profileInfos?.driverReferenceID}`,
        'Payroll Period Start:': `${selectedPayrollPeriod?.startDate}`,
        'Payroll Period End:': `${selectedPayrollPeriod?.endDate}`,
        'Total:': `${sumBy(payrollEntries, 'amount') || ''}`,
        'Total Hours:': `${calculateTotal(getTotalHoursRecords(), 'quantity') || ''}`,
    };

    const formatPayrollEntriesForExport = (data) => {
        return data.map((item) => ({
            startTime: item.startTime,
            endTime: item.endTime,
            description: item.description,
            destination: item.destination,
            tripReference: item.tripReference,
            payRateType: item.payRateType,
            quantity: item.quantity,
            units: item.units,
            payrate: item.payrate,
            famount: item.famount,
        }));
    };

    const getFormatPayrollDataSetForPDF = () => {
        const headerFields = {
            'Driver Name:': `${profileInfos?.firstName} ${profileInfos?.lastName}`,
            'Driver ID:': `${profileInfos?.driverReferenceID}`,
            'Payroll Period Start:': `${selectedPayrollPeriod?.startDate}`,
            'Payroll Period End:': `${selectedPayrollPeriod?.endDate}`,
            'Total:': `${sumBy(payrollEntries, 'amount') || ''}`,
            'Total Hours:': `${calculateTotal(getTotalHoursRecords(), 'quantity') || ''}`,
        };
        return [
            {
                headerData: headerFields,
                tableData: payrollEntries,
            },
        ];
    };

    return (
        <DetailsPageLayout title="Driver" icon="fa fa-id-card" textColor={tripSettings?.textColor}>
            <div className="note-text">
                For any changes or updates to this information please contact a company representative.
            </div>
            <Card>
                <Card.Header className="d-flex" style={{ backgroundColor: `${tripSettings?.mainColor}` }}>
                    <h3 className="text-white m-0 w-50">{profileTitle}</h3>
                </Card.Header>
                <Card.Body className="light-gray-bg">
                    {/* CONTACT INFORMATION */}
                    <CustomAccordion
                        key={1}
                        id={1}
                        title="CONTACT INFORMATION"
                        handleItemCollapse={handleItemCollapse}
                        sectionColor={tripSettings?.sectionColor}
                        collapseItem={collapseItem}
                    >
                        <Row>
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        placeholder="First Name"
                                        {...register('firstName')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Salutation</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="salutation"
                                        name="salutation"
                                        placeholder="Salutation"
                                        {...register('salutation')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Preferred Vehicle</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="preferredVehicle"
                                        name="preferredVehicle"
                                        placeholder="Preferred Vehicle"
                                        {...register('preferredVehicle')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Mobile Phone #</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="mobilePhone"
                                        name="mobilePhone"
                                        placeholder="Mobile Phone #"
                                        {...register('mobilePhone')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Middle Initial</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="middleInitial"
                                        name="middleInitial"
                                        placeholder="Middle Initial"
                                        {...register('middleInitial')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        placeholder="Last Name"
                                        {...register('lastName')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="email"
                                        name="email"
                                        placeholder="Email"
                                        {...register('email')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Home Phone #</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="homePhone"
                                        name="homePhone"
                                        placeholder="Home Phone #"
                                        {...register('homePhone')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Alternate Phone #</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="altPhone"
                                        name="altPhone"
                                        placeholder="Alternate Phone #"
                                        {...register('altPhone')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={8} lg={4} className="m-auto">
                                <FormGroup>
                                    <Form.Label>Address</Form.Label>
                                    <Row className="no-gutters mx-0">
                                        <Col xs={12} className={'mt-2'}>
                                            <Form.Control
                                                type="text"
                                                name="addressStreet1"
                                                id="addressStreet1"
                                                {...register('addressStreet1')}
                                                placeholder="Address 1"
                                                readOnly
                                            />
                                        </Col>
                                        <Col xs={12} className="mt-2">
                                            <Form.Control
                                                type="text"
                                                name="addressStreet2"
                                                id="addressStreet2"
                                                {...register('addressStreet2')}
                                                placeholder="Address 2"
                                                readOnly
                                            />
                                        </Col>
                                        <Col xs={12} className="mt-2">
                                            <Form.Control
                                                type="text"
                                                name="addressCity"
                                                id="addressCity"
                                                {...register('addressCity')}
                                                placeholder="City"
                                                readOnly
                                            />
                                        </Col>
                                        <Col xs={12} md={7} className="mt-2">
                                            <Form.Control
                                                type="text"
                                                name="addressState"
                                                id="addressState"
                                                {...register('addressState')}
                                                placeholder="State"
                                                readOnly
                                            />
                                        </Col>
                                        <Col xs={12} md={5} className="mt-2 pl-0 pl-md-3">
                                            <Form.Control
                                                type="text"
                                                id="addressZip"
                                                name="addressZip"
                                                {...register('addressZip')}
                                                placeholder="Zip"
                                                readOnly
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CustomAccordion>

                    <CustomAccordion
                        key={2}
                        id={2}
                        title="EMPLOYMENT INFORMATION"
                        handleItemCollapse={handleItemCollapse}
                        sectionColor={tripSettings?.sectionColor}
                        collapseItem={collapseItem}
                    >
                        <Row>
                            {/*Employment Status*/}
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Employment Status</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="employmentStatus"
                                        name="employmentStatus"
                                        placeholder="Employment Status"
                                        {...register('employmentStatus')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            {/*Date of Employment - Start*/}
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Date of Employment - Start</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="dateEmploymentStart"
                                        name="dateEmploymentStart"
                                        placeholder="Date of Employment - Start"
                                        {...register('dateEmploymentStart')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} lg={6}>
                                <FormGroup>
                                    <Form.Label>Primary Location</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="primaryLocation"
                                        name="primaryLocation"
                                        placeholder="Primary Location"
                                        {...register('primaryLocation')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            {/*License Number*/}
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>License Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="licenseNumber"
                                        name="licenseNumber"
                                        placeholder="License Number"
                                        {...register('licenseNumber')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            {/*License Issued State*/}
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>License Issued State</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="licenseIssuedState"
                                        name="licenseIssuedState"
                                        placeholder="License Issued State"
                                        {...register('licenseIssuedState')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            {/*License Issued Date*/}
                            <Col xs={12} md={6} lg={2}>
                                <FormGroup>
                                    <Form.Label>License Issued Date</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="dateLicenseIssued"
                                        name="dateLicenseIssued"
                                        placeholder="License Issued Date"
                                        {...register('dateLicenseIssued')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            {/*License Expiration Date*/}
                            <Col xs={12} md={6} lg={2}>
                                <FormGroup>
                                    <Form.Label>License Expiration Date</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="dateLicenseExpired"
                                        name="dateLicenseExpired"
                                        placeholder="License Expiration Date"
                                        {...register('dateLicenseExpired')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            {/*Date of Birth*/}
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Date of Birth</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="dateOfBirth"
                                        name="dateOfBirth"
                                        placeholder="Date of Birth"
                                        {...register('dateOfBirth')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            {/*Medical Card Expiration Date*/}
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Medical Card Expiration Date</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="dateMedicalCardExpiration"
                                        name="dateMedicalCardExpiration"
                                        placeholder="Medical Card Expiration Date"
                                        {...register('dateMedicalCardExpiration')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                                <FormGroup>
                                    <Form.Label>Passport Expiration Date</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="datePassportExpiration"
                                        name="datePassportExpiration"
                                        placeholder="Passport Expiration Date"
                                        {...register('datePassportExpiration')}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </CustomAccordion>

                    {tripSettings && tripSettings?.showDriverPayrollSummaryInDriverPortal ? (
                        <CustomAccordion
                            id={5}
                            key={5}
                            collapseItem={collapseItem}
                            handleItemCollapse={handleItemCollapse}
                            title="DRIVER PAYROLL"
                            sectionColor={tripSettings?.sectionColor}
                        >
                            <Row className="m-2">
                                <Col lg={6}>
                                    <Form.Label>Payroll Period</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={payrollPeriodID}
                                        className="w-25"
                                        onChange={(e) => setPayrollPeriodID(e.target.value)}
                                    >
                                        <option value="">No Selection</option>
                                        {payrollPeriods.map((pp) => {
                                            return (
                                                <option
                                                    key={pp.payrollPeriodID}
                                                    id={pp.payrollPeriodID}
                                                    value={pp.payrollPeriodID}
                                                >{`${pp.startDate} - ${pp.endDate}`}</option>
                                            );
                                        })}
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="m-2">
                                <Col>
                                    <SimpleTable
                                        compactTable
                                        data={payrollEntries}
                                        showFooter={Boolean(payrollEntries?.length)}
                                        loading={loadingPE}
                                        compactHeight={
                                            payrollEntries?.length
                                                ? payrollEntries.length > 5
                                                    ? '500px'
                                                    : '250px'
                                                : '100px'
                                        }
                                        fieldNames={[
                                            [
                                                'startTime',
                                                'Start Date',
                                                undefined,
                                                <Button className="btn text-uppercase" key={1}>
                                                    <PdfWithHeaderExport
                                                        buttonclass="btn text-uppercase"
                                                        // disabled={!driverId}
                                                        size="a3"
                                                        orientation="landscape"
                                                        cols={payrollEntriesCol}
                                                        getFormatedDataSet={getFormatPayrollDataSetForPDF}
                                                        headerFields={headerFields}
                                                        formatDataForExport={formatPayrollEntriesForExport}
                                                        name={`Payroll Entries Report`}
                                                    />
                                                </Button>,
                                            ],
                                            ['endTime', 'End Date'],
                                            ['description', 'Description'],
                                            ['destination', 'Destination'],
                                            ['tripReference', 'Trip Reference'],
                                            ['payRateType', 'Pay Rate Type'],
                                            ['quantity', 'Quantity'],
                                            ['units', 'Units'],
                                            ['payrate', 'Pay Rate'],
                                            ['famount', 'Amount', undefined, `${sumBy(payrollEntries, 'amount')}`],
                                        ]}
                                    />
                                </Col>
                            </Row>
                        </CustomAccordion>
                    ) : (
                        ''
                    )}
                </Card.Body>
            </Card>
            {loading && <Loader />}
        </DetailsPageLayout>
    );
};

export default ProfilePage;
