import _ from 'lodash';
import moment from 'moment-timezone';
import { timezoneDescription } from '../config';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

export const formatCurrency = (currency) => {
    currency = Number(currency).toFixed(2);
    let cur = currency.toString().split('.');
    return `${cur[0].toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,')}.${cur[1] ? cur[1] : '00'}`;
};

export const totalAmountValues = (data, key) => {
    return `$${formatCurrency(_.sumBy(data, (b) => b[key]))}`;
};

export const formatToMMDDYYYY = (date) => {
    const timezone = timezoneDescription();
    return dayjs.utc(date).tz(timezone).format('MM/DD/YYYY');
};

export const calculatePagination = (list = [], limit) => {
    let first = 0;
    let last = first + limit;
    let totalPages = typeof list.length != 'undefined' ? Math.ceil(list.length / limit) : 1;
    let pages = [...Array(totalPages).keys()];
    return { first, last, totalPages, pages, currrentPage: 0 };
};

export const convertDurationToTime = (duration) => {
    // return format is `N hrs O mins`
    let timeData = moment.duration(Number(duration), 'hours')._data;
    let timeStr = `${timeData.hours > 0 ? timeData.hours + ' hrs ' : ''}${
        timeData.minutes > 0 ? timeData.minutes + ' mins' : ''
    }`;
    return timeStr === '' ? '0' : timeStr;
};

export const isDate = function (date) {
    return new Date(date) !== 'Invalid Date' && !isNaN(new Date(date));
};

export const listingTableSort = (d1, d2, sortConfig) => {
    let returnVal1 = 0,
        returnVal2 = 0,
        temp1,
        temp2;
    returnVal1 = (typeof d1[sortConfig[0]] === 'undefined') - (typeof d2[sortConfig[0]] === 'undefined');
    if (sortConfig[1]) {
        // ascending
        if (sortConfig[0] === 'bookingId' || sortConfig[0] === 'quotesId') {
            if (
                String(d1[sortConfig[0]]).toString().includes('-') &&
                String(d2[sortConfig[0]]).toString().includes('-')
            ) {
                temp1 = d1[sortConfig[0]].toString().split('-');
                temp2 = d2[sortConfig[0]].toString().split('-');
                returnVal2 =
                    temp1[0] === temp2[0]
                        ? Number(temp1[1]) < Number(temp2[1])
                            ? -1
                            : 1
                        : Number(temp1[0]) < Number(temp2[0])
                        ? -1
                        : 1;
            } else if (d1[sortConfig[0]].toString().includes('-') || d2[sortConfig[0]].toString().includes('-')) {
                returnVal2 = d1[sortConfig[0]].toString().includes('-') ? -1 : 1;
            } else returnVal2 = Number(d1[sortConfig[0]]) < Number(d2[sortConfig[0]]) ? -1 : 1;
        } else {
            if (isDate(d1[sortConfig[0]])) {
                returnVal2 = new Date(d1[sortConfig[0]]).valueOf() < new Date(d2[sortConfig[0]]).valueOf() ? -1 : 1;
            } else if (typeof d1[sortConfig[0]] === 'string' && d2[sortConfig[0]]) {
                returnVal2 = isNaN(d1[sortConfig[0]]) // check if value is number
                    ? d1[sortConfig[0]].toLowerCase() < d2[sortConfig[0]].toLowerCase()
                        ? -1
                        : 1
                    : Number(d1[sortConfig[0]]) < Number(d2[sortConfig[0]])
                    ? -1
                    : 1;
            } else {
                returnVal2 = isNaN(d1[sortConfig[0]]) // check if value is number
                    ? String(d1[sortConfig[0]]).toLowerCase() < String(d2[sortConfig[0]]).toLowerCase()
                        ? -1
                        : 1
                    : Number(d1[sortConfig[0]]) < Number(d2[sortConfig[0]])
                    ? -1
                    : 1;
            }
        }
    } else {
        // descending
        if (sortConfig[0] === 'bookingId' || sortConfig[0] === 'quotesId') {
            if (
                String(d1[sortConfig[0]]).toString().includes('-') &&
                String(d2[sortConfig[0]]).toString().includes('-')
            ) {
                temp1 = d1[sortConfig[0]].toString().split('-');
                temp2 = d2[sortConfig[0]].toString().split('-');
                returnVal2 =
                    temp1[0] === temp2[0]
                        ? Number(temp1[1]) > Number(temp2[1])
                            ? -1
                            : 1
                        : Number(temp1[0]) > Number(temp2[0])
                        ? -1
                        : 1;
            } else if (d1[sortConfig[0]].toString().includes('-') || d2[sortConfig[0]].toString().includes('-')) {
                returnVal2 = d1[sortConfig[0]].toString().includes('-') ? -1 : 1;
            } else returnVal2 = Number(d1[sortConfig[0]]) > Number(d2[sortConfig[0]]) ? -1 : 1;
        } else {
            if (isDate(d1[sortConfig[0]])) {
                returnVal2 = new Date(d1[sortConfig[0]]).valueOf() > new Date(d2[sortConfig[0]]).valueOf() ? -1 : 1;
            } else
                returnVal2 = isNaN(d1[sortConfig[0]]) // check if value is numbers
                    ? String(d1[sortConfig[0]]).toLowerCase() > String(d2[sortConfig[0]]).toLowerCase()
                        ? -1
                        : 1
                    : Number(d1[sortConfig[0]]) > Number(d2[sortConfig[0]])
                    ? -1
                    : 1;
        }
    }
    return returnVal1 || returnVal2;
};

export const removeAuthInfoFromLocalStorage = () => {
    localStorage.removeItem('driverportal_version');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    sessionStorage.removeItem('lastTimeStamp');
};

export const calculateTotal = (dataSet, field) => {
    let total = _.sumBy(dataSet, function (rec) {
        return Number(rec[`${field}`] ? rec[`${field}`] : 0);
    });
    return total.toFixed(2);
};
