import React, { useEffect, useState } from 'react';
import { getProfileInfo, useProfilePageState, useAccountState, getTripSettings } from '../../../../Context';
import { Form, Row, Spinner, Table } from 'react-bootstrap';
import Loader from '../../../../Components/Loader';
import { useForm } from 'react-hook-form';
import './index.scss';
import { getPayrollPeriodsService } from '../../../../services/payrollPeriodsService';
import moment from 'moment';
import { getPayrollEntriesService } from '../../../../services/payrollEntriesService';
import PdfWithHeaderExport from '../../../../Components/PdfWithHeaderExport';
import { sumBy } from 'lodash';
import { calculateTotal } from '../../../../utils/helper';
import { isEqual } from 'lodash';

const ProfilePage = () => {
    const { profilePageDispatch } = useProfilePageState();
    const [loading, setLoading] = useState(false);
    const { reset } = useForm();
    const [profileTitle, setProfileTitle] = useState();
    const [profileDetail, setProfileDetail] = useState({});
    const [payrollPeriodID, setPayrollPeriodID] = useState();
    const [payrollPeriods, setPayrollPeriods] = useState([]);
    const [payrollEntries, setPayrollEntries] = useState([]);
    const [selectedPayrollPeriod, setSelectedPayrollPeriod] = useState();
    const [loadingPE, setLoadingPE] = useState(false);

    const {
        AccountsDispatch,
        tripSettings: { tripSettings },
    } = useAccountState();

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getTripSettings(AccountsDispatch);
            let driverDetail = await getProfileInfo(profilePageDispatch);
            reset({ ...driverDetail });
            setProfileDetail(driverDetail);
            driverDetail && setProfileTitle(`${driverDetail.firstName}  ${driverDetail.lastName}`);

            let { data: pplist } = await getPayrollPeriodsService();
            setPayrollPeriods(pplist);
            const tempPPID = pplist.find(({ startDate, endDate }) =>
                moment().isBetween(moment(startDate), moment(endDate))
            );

            if (tempPPID?.payrollPeriodID) setPayrollPeriodID(tempPPID.payrollPeriodID);
            setLoading(false);
        })();
    }, [AccountsDispatch, profilePageDispatch, reset]);

    useEffect(() => {
        (async () => {
            if (payrollPeriodID) {
                setLoadingPE(true);
                const { data: fetchedPayrollEntries } = await getPayrollEntriesService(payrollPeriodID);
                setPayrollEntries(fetchedPayrollEntries);
                setLoadingPE(false);
            }
            if (payrollPeriodID) {
                setSelectedPayrollPeriod(payrollPeriods.find((pp) => pp?.payrollPeriodID == payrollPeriodID));
            }
        })();
    }, [payrollPeriodID, payrollPeriods]);

    const combineAddress = (add) => {
        const { streetAddress1 = '', streetAddress2 = '', addressCity = '', zipAddress = '', addressState = '' } = add;
        return [streetAddress1, streetAddress2, addressCity, addressState, zipAddress].filter(Boolean).join(', ');
    };
    const columns = [
        { id: 'startDate', label: 'Start Date' },
        { id: 'endDate', label: 'End Date' },
        { id: 'description', label: 'Description' },
        { id: 'destination', label: 'Destination' },
        { id: 'tripReference', label: 'Trip Reference' },
        { id: 'payRateType', label: 'Pay Rate Type' },
        { id: 'quantity', label: 'Quantity', align: 'right' },
        { id: 'units', label: 'Units' },
        { id: 'payRate', label: 'Pay Rate' },
        { id: 'amount', label: 'Amount', align: 'right' },
    ];
    const styles = {
        tableHeader: {
            backgroundColor: 'gray',
            color: 'white',
            verticalAlign: 'middle',
            paddingTop: '0',
            paddingBottom: '0',
            fontSize: '0.8rem',
        },
        rightAlign: {
            textAlign: 'right',
        },
        tableRow: {
            verticalAlign: 'middle',
            fontSize: '0.8rem',
        },
    };
    const getTotalHoursRecords = () => {
        const records = [];
        payrollEntries
            .filter((pe) => [1, 2, 3, 4, 7].includes(pe.payRateDataSourceID))
            .forEach((pe) => {
                const recordAlreadyExists = records.some((r) =>
                    isEqual(
                        {
                            description: r.description,
                            startTime: r.startTime,
                            endTime: r.endTime,
                            quantity: r.quantity,
                        },
                        {
                            description: pe.description,
                            startTime: pe.startTime,
                            endTime: pe.endTime,
                            quantity: pe.quantity,
                        }
                    )
                );

                if (!recordAlreadyExists) {
                    records.push(pe);
                }
            });
        return records;
    };
    const payrollEntriesCol = [
        { key: 'startTime', label: 'Start Date' },
        { key: 'endTime', label: 'End Date' },
        { key: 'description', label: 'Description' },
        { key: 'destination', label: 'Destination' },
        { key: 'tripReference', label: 'Trip Reference' },
        { key: 'payRateType', label: 'Pay Rate Type' },
        { key: 'quantity', label: 'Quantity' },
        { key: 'units', label: 'Units' },
        { key: 'payrate', label: 'Pay Rate' },
        { key: 'famount', label: 'Amount' },
    ];

    const headerFields = {
        'Driver Name:': `${profileDetail?.firstName} ${profileDetail?.lastName}`,
        'Driver ID:': `${profileDetail?.driverReferenceID}`,
        'Payroll Period Start:': `${selectedPayrollPeriod?.startDate}`,
        'Payroll Period End:': `${selectedPayrollPeriod?.endDate}`,
        'Total:': `${sumBy(payrollEntries, 'amount') || ''}`,
        'Total Hours:': `${calculateTotal(getTotalHoursRecords(), 'quantity') || ''}`,
    };

    const formatPayrollEntriesForExport = (data) => {
        return data.map((item) => ({
            startTime: item.startTime,
            endTime: item.endTime,
            description: item.description,
            destination: item.destination,
            tripReference: item.tripReference,
            payRateType: item.payRateType,
            quantity: item.quantity,
            units: item.units,
            payrate: item.payrate,
            famount: item.famount,
        }));
    };

    const getFormatPayrollDataSetForPDF = () => {
        const headerFields = {
            'Driver Name:': `${profileDetail?.firstName} ${profileDetail?.lastName}`,
            'Driver ID:': `${profileDetail?.driverReferenceID}`,
            'Payroll Period Start:': `${selectedPayrollPeriod?.startDate}`,
            'Payroll Period End:': `${selectedPayrollPeriod?.endDate}`,
            'Total:': `${sumBy(payrollEntries, 'amount') || ''}`,
            'Total Hours:': `${calculateTotal(getTotalHoursRecords(), 'quantity') || ''}`,
        };
        return [
            {
                headerData: headerFields,
                tableData: payrollEntries,
            },
        ];
    };

    return (
        <>
            <div className="w-100 mb-3 mt-1 content-section" style={{ minHeight: 'auto' }}>
                <Row className="mx-2">
                    <div className="col-12 head-div mt-2">
                        <div>
                            <span className="sg-card-lt">{'Contact Information'}</span>
                        </div>
                    </div>
                    <div className="col-12 ">
                        <span className="dtail-bold">{profileTitle}</span>
                        {profileDetail.mobilePhone && (
                            <span className="dtail">Mobile: {profileDetail.mobilePhone}</span>
                        )}
                        {profileDetail.homePhone && <span className="dtail">Home: {profileDetail.homePhone}</span>}
                        {profileDetail.email && <span className="dtail">Email: {profileDetail.email}</span>}
                        {(profileDetail.addressStreet1 || profileDetail.addressStreet2) && (
                            <span className="dtail">
                                Adddress:{' '}
                                {combineAddress({
                                    streetAddress1: profileDetail.addressStreet1,
                                    streetAddress2: profileDetail.addressStreet2,
                                    addressCity: profileDetail.addressCity,
                                    addressState: profileDetail.addressState,
                                    zipAddress: profileDetail.addressZip,
                                })}
                            </span>
                        )}
                        {profileDetail.dateOfBirth && <span className="dtail">DOB: {profileDetail.dateOfBirth}</span>}
                    </div>
                    <div className="col-12 head-div mt-2">
                        <div>
                            <span className="sg-card-lt">{'Employment Information'}</span>
                        </div>
                    </div>
                    <div className="col-12 ">
                        <span className="dtail">Employment status: {profileDetail.employmentStatus}</span>
                        <span className="dtail">Start of Employment: {profileDetail.dateEmploymentStart}</span>
                        <span className="dtail">Primary Location: {profileDetail.primaryLocation}</span>
                    </div>
                    <div className="col-12 head-div mt-2">
                        <div>
                            <span className="sg-card-lt">{'License Information'}</span>
                        </div>
                    </div>
                    <div className="col-12 ">
                        <span className="dtail">License Number: {profileDetail.licenseNumber}</span>
                        <span className="dtail">License State: {profileDetail.licenseIssuedState}</span>
                        <span className="dtail">License Issue Date: {profileDetail.dateLicenseIssued}</span>
                        <span className="dtail">License Expire Date: {profileDetail.dateLicenseExpired}</span>
                        <span className="dtail mt-2">
                            Medical Card Expiration Date: {profileDetail.dateMedicalCardExpiration}
                        </span>
                        <span className="dtail">Passport Expiration Date: {profileDetail.datePassportExpiration}</span>
                    </div>
                </Row>
                {tripSettings && !!tripSettings?.showDriverPayrollSummaryInDriverPortal && (
                    <Row className="mx-2 w-100">
                        <div className="col-12 head-div mt-2">
                            <div>
                                <span className="sg-card-lt">{'Driver Payroll'}</span>
                            </div>
                        </div>
                        <Row className="m-2  ">
                            <div>
                                {!loadingPE ? (
                                    <>
                                        <Form.Label style={{ fontSize: '12px' }}>Payroll Period</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={payrollPeriodID}
                                            size="sm"
                                            htmlSize={1}
                                            style={{ fontSize: '12px' }}
                                            onChange={(e) => setPayrollPeriodID(e.target.value)}
                                        >
                                            <option value="">No Selection</option>
                                            {payrollPeriods.map((pp) => {
                                                return (
                                                    <option
                                                        key={pp.payrollPeriodID}
                                                        id={pp.payrollPeriodID}
                                                        value={pp.payrollPeriodID}
                                                    >{`${pp.startDate} - ${pp.endDate}`}</option>
                                                );
                                            })}
                                        </Form.Control>
                                    </>
                                ) : (
                                    <Spinner animation="border" size="sm" />
                                )}
                            </div>
                            {payrollEntries.length > 0 && !loadingPE && (
                                <div
                                    style={{
                                        marginLeft: '0.75rem',
                                        marginTop: '1.5rem',
                                        border: '1px solid black',
                                        borderRadius: '5px',
                                    }}
                                >
                                    <PdfWithHeaderExport
                                        buttonclass="btn-sm text-uppercase p-1 small "
                                        size="a3"
                                        orientation="landscape"
                                        cols={payrollEntriesCol}
                                        getFormatedDataSet={getFormatPayrollDataSetForPDF}
                                        headerFields={headerFields}
                                        formatDataForExport={formatPayrollEntriesForExport}
                                        name={`Payroll Entries Report`}
                                    />
                                </div>
                            )}
                        </Row>
                        {payrollEntries.length > 0 && !loadingPE ? (
                            <>
                                <div className="d-md-block w-100 m-2">
                                    <div style={{ overflowX: 'auto' }}>
                                        <Table responsive>
                                            <thead style={styles.tableHeader}>
                                                <tr>
                                                    {columns.map((column) => (
                                                        <th key={column.id} style={styles.tableHeader}>
                                                            {column.label}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {payrollEntries.map((pe, i) => (
                                                    <tr key={i} style={styles.tableRow}>
                                                        {columns.map((column) => (
                                                            <td
                                                                key={`${i}-${column.id}`}
                                                                style={
                                                                    column.align === 'right' ? styles.rightAlign : {}
                                                                }
                                                            >
                                                                {pe[column.id]}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </Row>
                )}
            </div>
            {loading && <Loader />}
        </>
    );
};

export default ProfilePage;
